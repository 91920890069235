.steps{

}

.steps .steps-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
}

.steps .steps-header h2{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 16px;
}


.steps .steps-header h2 i{
    color: #028ad3;
}

.steps .steps-header h1{
    direction: rtl;
    font-weight: 600;
    color: #000;
    margin-bottom: 27px;
}


.steps .step{
    background-image: url(http://localhost:3000/static/media/triangles.5590ae1….webp);
    background-size: cover;
    background-position: center;
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.steps .step i{
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #21212124;
    font-size: 40px;
    margin-top: -69px;
    margin-bottom: 45px;
}

.steps .step h4{
    font-weight: 500;
    color: #000;
    font-size: 21px;
    margin-bottom: 20px;
    text-align: center;
}

.steps .step p{
    text-align: center;
    width: 73%;
}


.steps .step span{
    font-size: 22px;
    font-weight: 500;
    color: #000;
}