footer{
    background-image: url("../images/footerback.webp");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding-top: 113px;
    position: relative;
}


footer .overlay{
    position: absolute;
    inset: 0;
    background-color: #028ad3ba;
}

footer .footer-content{
    position: relative;
    z-index: 2;
}


footer .footer-content .logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


footer .footer-content .logo img{
    width: 133px;
}

footer .footer-content p{
    text-align: right;
    color: #fff;
    font-size: 18px;
}


footer .footer-content h1{
    font-size: 20px;
    text-align: right;
    color: #fff;
    margin-bottom: 32px;
}

footer .footer-content .service{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 26px;
    margin-bottom: 42px;
}

footer .footer-content .service img{
    width: 100px;
    height: 80px;
    object-fit: cover;
}


footer .footer-content .service div{
    text-align: right;
}

footer .footer-content .service div h4{
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}

footer .footer-content .service div p{
    text-align: right;
    color: #fff;
    font-size: 18px;
}


footer .footer-content ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 33px;
}



footer .footer-content ul li a{
    color: #fff;
    color: #dcdcdc;
}


footer .footer-content>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 54px;
    color: #000;
    gap: 15px;
    font-size: 19px;
    font-weight: 400;
}

footer .copy-right{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #ffffff6b;
    position: relative;
}

footer .copy-right .socials{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    gap: 25px;
    color: #fff;
}

footer .copy-right .socials a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
}

footer .copy-right h5{
    font-size: 15px;
    color: #fff;
}

@media(max-width:950px){
    footer .copy-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #ffffff6b;
        position: relative;
        gap: 22px;
        margin-top: 17px;
    }
    
}