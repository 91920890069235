
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body{
  background-color: #fff;
  scroll-behavior: smooth;

}

*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Tajawal', sans-serif;
  
}

/* font-family: 'IBM Plex Sans Arabic', sans-serif; */

a{
  text-decoration: none !important;
}

ul{
  list-style: none !important;
  margin: 0 !important;
}


.hide{
  display: none !important;
}




.lang a{
  position: absolute;
  z-index: 5;
  left: 15px;
  top: 34px;
  color: #fff;
  font-size: 21px;
}






.loading-screen .loader {
  position: relative;
}

.loading-screen .outer,
.loading-screen .middle,
.loading-screen .inner {
  border: 3px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-screen .outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.loading-screen .middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.loading-screen .inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}




.loading-screen{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000087;
  z-index: 1000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: none;
}


.loading-screen.active{
  display: flex;
}



.error-message{
  position: fixed;
  bottom: 2%;
  right: 1%;
  background-color: #ff6b6b;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  transition: 0.3s;
  min-width: 380px;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.success-message{
  position: fixed;
  bottom: 2%;
  right: 1%;
  background-color: #00cc66; /* Green background */
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  transition: 0.3s;
  min-width: 380px;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

}



/* CSS for the modal */
.pay-res-modal {
  position: fixed;
  z-index: 9999; /* Ensure it appears on top of other elements */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  overflow: auto;
}

/* CSS to center the image */
.pay-res-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Style for the image in the modal */
.pay-res-modal img {
  max-width: 80%; /* Adjust the size as needed */
  max-height: 80%;
  border: 3px solid white; /* Add a border around the image */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); /* Add a shadow effect */
}

/* Style to close the modal */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

/* Show the modal when clicked */
.pay-res:hover .pay-res-modal {
  display: block;
}

/* Hide the scrollbar when modal is open */
body.modal-open {
  overflow: hidden;
}
