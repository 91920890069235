


.map .map-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-color: #028ad3;
    margin-top: -49px;
    margin-bottom: -68px;
    z-index: 3;
}

.map .map-content .contact-info .info{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
}

.map .map-content .contact-info .info i{
    font-size: 44px;
    color: #ffff;
}

.map .map-content .contact-info .info div{
    text-align: center;
}

.map .map-content .contact-info .info div h3{
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-top: 17px;
}

.map .map-content .contact-info .info div a{
    font-size: 15px;
    color: #fff;
    text-align: center;
}

@media(max-width:950px){
    .map .map-content .contact-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: #028ad3;
        margin-top: -49px;
        margin-bottom: -68px;
        z-index: 3;
        padding: 18px;
    }

    .map .map-content .contact-info .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 150px;
    }
}