.products{
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #000;
    margin-bottom: 120px;
}

.products>img{
    inset: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}

.products .products-header{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 60px;
}

.products .products-header h2{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 16px;
    color: #fff;
}

.products .products-header h1{
    direction: rtl;
    font-weight: 600;
    color: #fff;
    margin-bottom: 27px;
}

.products .product{
    position: relative;
}

.products .product img{

    width: 100%;
    height: 350px;
    object-fit: cover;
}

.products .product .text{
    position: absolute;
    width: 87%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    padding: 18px;
    min-height: 153px;
    direction: rtl;
}
.products .product .text div{
    width: 80%;
}

.products .product .text h3{
    font-size: 16px;
    color: #028ad3;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.products .product .text h3 span{
    width: 60px;
    height: 1px;
    background-color: #028ad4;
}




.products .product .text h1{
    font-size: 19px;
    font-weight: 700;
}


.products .product .text p{
    font-size: 15px;
}


.products .product .text a{
    width: 60px;
    height: 60px;
    background-color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    font-size: 29px;
}