.solutions{
    position: relative;
    margin-bottom: 120px;
}

.solutions>img{
    width: 120px;
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 15;
    border-radius: 50%;
    height: 120px;
    padding: 19px;
    object-fit: contain;
    border: 1px solid #0000001f;
}

.solutions .service-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
}

.solutions .service-header h1{
    font-size: 52px;
    color: #000;
    font-weight: 700;
}

.solutions .service-header p{
    font-size: 18px;
    margin-top: 31px;
    color: #6d6d6d;
}

.solutions .service-header a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 60px;
    background-color: #028ad3;
    color: #fff;
    font-size: 18px;
    margin-top: 60px;
}

.solutions .images img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.solutions .text{
    position: relative;
    min-height: 600px;
    background-color: #028ad3;
    padding: 31px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    padding-top: 77px;
}




.solutions .text img{
    inset: 0;
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    inset: 0;
    width: 100%;
    height: 100%;
}

.solutions .text h2{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 16px;
    color: #fff;
}

.solutions .text h1{
    position: relative;
    font-weight: 600;
    color: #fff;
    margin-bottom: 27px;
}

.solutions .text>p{
    position: relative;
    width: 83%;
    font-size: 17px;
    line-height: 29px;
    color: #e9e8e8;
    text-align: right;
}


.solutions .text .solution{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 17px;
    margin-top: 26px;
}


.solutions .text .solution i{
    font-size: 50px;
    color: #fff;
}

.solutions .text .solution div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.solutions .text .solution div h3{
    color: #fff;
    font-weight: 500;
    font-size: 19px;
}

.solutions .text .solution div p{
    position: relative;
    width: 83%;
    font-size: 17px;
    line-height: 29px;
    color: #e9e8e8;
    text-align: right;
}

@media(max-width:950px){
    .solutions .service-header h1 {
        font-size: 37px;
        color: #000;
        font-weight: 700;
        text-align: right;
    }
}