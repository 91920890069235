.services{
    background-image: url('../images/servicesback.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 696px;
    position: relative;
}

.services .overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #fffffff0;
}




.services .services-header{
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 64px;
}

.services .services-header .left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.services .services-header .left h2{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 16px;
}

.services .services-header .left h1{
    font-weight: 600;
    color: #000;
    margin-bottom: 27px;
}

.services .services-header .right{
    width: 50%;
}

.services .services-header .right p{
    width: 83%;
    font-size: 17px;
    line-height: 29px;
    color: #4f4f4f;
    text-align: right;
}

.services .service{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    justify-content: space-between;
    min-height: 368px;
    background-color: #028ad3;
    padding: 35px;
    margin-top: 30px;
    overflow: hidden;
    padding-bottom: 45px;
}

.services .service .icon{
    position: relative;
}

.services .service .icon i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    /* background-color: #fff; */
    border-radius: 50%;
    font-size: 48px;
    color: #fff;
}

.services .service .icon span{
    width: 47px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    bottom: 3px;
    right: -3px;
    background-color: #ffffff42;
}

.services .service h2{
    font-size: 22px;
    font-weight: 600;
    color: #fff;
}

.services .service p{
    text-align: center;
    color: #fff;
    min-height: 90px;
}

.services .service a{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 91%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 120px;
    background-color: #fff;
    border-radius: 75% 75% 15% 15%;
    bottom: -82px;
    padding-top: 16px;
    color: #000;
}

@media(max-width:950px){
    .services .services-header {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 64px;
    }

    .services .services-header .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
    }

    .services .services-header .right {
        width: 100%;
    }

    .services .services-header .right p {
        width: 100%;
        font-size: 17px;
        line-height: 29px;
        color: #4f4f4f;
        text-align: right;
    }
}