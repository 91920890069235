.benefits{
    margin-bottom: 250px;
}

.benefits .benefit{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
}

.benefits .benefit img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}


.benefits .benefit .text{
    background-image: url("../images/pngbacks/triangles.webp");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    width: 86%;
    position: absolute;
    bottom: -76px;
    min-height: 181px;
}


.benefits .benefit .text i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    background-color: #fff;
    margin-top: -60px;
    border-radius: 50%;
    border: 1px solid #00000021;
    font-size: 42px;
}

.benefits .benefit .text h2{
    font-size: 22px;
    margin-top: 18px;
    font-weight: 600;
    color: #000;
}

.benefits .benefit .text p{
    text-align: center;
    font-size: 16px;
    width: 78%;
    line-height: 23px;
    margin: 0;
    min-height: 60px;
}


@media(max-width:950px){
    .benefits .benefit {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 119px;
    }

    .benefits {
        margin-bottom: 100px;
    }
    
}