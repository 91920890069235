

.upper-nav .upper-nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 9px;
}

.upper-nav .upper-nav-content .logo{
    padding-right: 15px;
    border-right: 1px solid #00000063;
    height: 80px;
}

.upper-nav .upper-nav-content .logo img{
    width: 115px;
    height: 100%;
    object-fit: contain;
}


.upper-nav .upper-nav-content .contact-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 63px;
}

.upper-nav .upper-nav-content .contact-info div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.upper-nav .upper-nav-content .contact-info div>i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 50%;
    font-size: 24px;
}

.upper-nav .upper-nav-content .contact-info .text{
    display: flex;
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    gap: 0;
    width: 150px;
}

.upper-nav .upper-nav-content .contact-info .text h2{
    font-size: 17px;
    font-weight: 400;
}

.upper-nav .upper-nav-content .contact-info .text a{
    color: #000;
    font-weight: 600;
}


@media(max-width:950px){
    .upper-nav .upper-nav-content .contact-info>div:nth-child(2){
        display: none;
    }

    .upper-nav .upper-nav-content .contact-info>div:nth-child(3){
        display: none;
    }
}