.landing{
    margin-top: -20px;
    position: relative;
}

.landing .landing-content{
    position: relative;
    min-height: 600px;
}



.landing .landing-content img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    inset: 0;
    position: absolute;
}


.landing .landing-content .overlay{
    background: rgb(2,138,211);
    background: linear-gradient(50deg, rgba(2,138,211,1) 0%, rgba(255,255,255,0) 100%);
    inset: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.landing .landing-content .text{
    position: relative;
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    justify-content: center;
    min-height: 600px;
    padding: 15px;
}

.landing .landing-content .text h2{
    color: #fff;
    font-size: 19px;
}

.landing .landing-content .text h1{
    font-size: 69px;
    width: 53%;
    text-align: right;
    color: #fff;
    font-weight: 800;
}

.landing .landing-content .text p{
    color: #fff;
    direction: rtl;
}


.landing .landing-content .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 60px;
    background-color: #028ad3;
    color: #fff;
    font-size: 18px;
}


.landing .buttons{
    position: absolute;
    bottom: 0px;
    left: 10px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.landing .buttons button{
    background-color: #fff;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    font-size: 27px;
}


@media(max-width:950px){


    .landing .landing-content .text h1 {
        font-size: 40px;
        width: 100%;
        text-align: right;
        color: #fff;
        font-weight: 800;
    }
}




