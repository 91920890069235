.about{
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
}

.about>img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;
}

.about .text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
    direction: rtl;
}

.about .text h2{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 7px;
    font-size: 16px;
}

.about .text h2 i{
    color: #028ad3;
}

.about .text h1{
    font-weight: 600;
    color: #000;
    margin-bottom: 27px;
}

.about .text p{
    width: 83%;
    font-size: 17px;
    line-height: 29px;
    color: #4f4f4f;
}

.about .text .about-benefits{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.about .text .about-benefits .left{
    margin-top: 25px;
    margin-bottom: 26px;
    width: 60%;
}

.about .text .about-benefits .left div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.about .text .about-benefits .left div i{
    width: 80px;
    height: 80px;
    background-color: #028ad3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 41px;
    color: #fff;
    border-radius: 50%;
}

.about .text .about-benefits .left div .info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
}

.about .text .about-benefits .left div .info h3{
    font-weight: 600;
    color: #000;
    margin-bottom: 27px;
    font-size: 22px;
    margin: 0;
}

.about .text .about-benefits .left div .info p{
    width: 83%;
    font-size: 17px;
    line-height: 29px;
    color: #4f4f4f;
}

.about .text .about-benefits .right{
    width: 40%;
    height: 200px;
    background-color: #028ad3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about .text .about-benefits .right .precentage{
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 3px solid #fff;
    border-radius: 50%;
    position: relative;
}

.about .text .about-benefits .right .precentage span{
    position: absolute;
    top: 0;
    left: 15px;
    background-color: #028ad3;
    width: 30px;
    height: 3px;
}

.about .text .about-benefits .right .precentage h5{
    color: #fff;
    margin: 0;
}

.about .text .about-benefits .right h4{
    margin-top: 21px;
    color: #fff;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.about .text ul{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
}

.about .text ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.about .text ul li i{
    background-color: #028ad3;
    border-radius: 50%;
    color: #fff;
    padding: 5px;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.about .text>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    min-height: 52px;
    width: 353px;
    background-color: #028ad3;
    color: #fff;
    margin-top: 36px;
}


.about .text>a img{
    width: 47px;
    height: 47px;
    object-fit: contain;
}

.about .images{
    position: relative;
    direction: rtl;
    min-height: 650px;
}
.about .images img:nth-child(1){
    width: 91%;
    height: 600px;
    object-fit: cover;
}

.about .images img:nth-child(2){
    position: absolute;
    top: 20px;
    z-index: 15;
    right: 20px;
    background-color: #fff;
    width: 131px;
    border-radius: 50%;
    padding: 19px;
    height: 131px;
    object-fit: contain;
}



@media(max-width:950px){
    .about .text .about-benefits {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .about .text .about-benefits .left {
        margin-top: 25px;
        margin-bottom: 26px;
        width: 100%;
    }

    .about .text .about-benefits .right {
        width: 100%;
        height: 200px;
        background-color: #028ad3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
    }

    .about .images img:nth-child(1) {
        width: 100%;
        height: 450px;
        object-fit: cover;
        margin-top: 61px;
    }


    .about .images img:nth-child(2) {
        position: absolute;
        top: 74px;
        z-index: 15;
        right: 20px;
        background-color: #fff;
        width: 131px;
        border-radius: 50%;
        padding: 19px;
        height: 131px;
        object-fit: contain;
    }

    .about .images {
        position: relative;
        direction: rtl;
        min-height: 500px;
    }

}