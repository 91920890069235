nav{
    position: relative;
    z-index: 12;
}

nav .nav-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    height: 83px;
}

nav .nav-content .nav-elements{
    width: 80%;
    height: 100%;
}

nav .nav-content .nav-elements ul{
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 35px;
    background-color: #eee;
    justify-content: center;
    height: 100%;
    border: 1px solid #fff;
    align-items: center;
}


nav .nav-content .nav-elements ul li a{
    color: #000;
    font-size: 17px;
}

nav .nav-content>a{
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #028ad3;
    color: #fff;
    font-size: 16px;
    gap: 9px;
}



#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 15015;
    }

    nav .nav-content .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        height: inherit;
        background-color: #fff;
        margin: 0;
        height: 181px;
    }

    
    nav .nav-content .nav-elements{
        display: none;
    }

    nav .nav-content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav {
        position: absolute;
        width: 100%;
        margin-top: 94px;
        left: 0;
        right: 0;
        z-index: 3;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #ffffff21;
    }

    nav .nav-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
    nav.active{
        position: inherit !important;
    }

    nav {
        position: relative;
        width: 100%;
        margin-top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #ffffff21;
        background-color: #eee;
        z-index: 151;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    nav .nav-content>a {
        width: 77%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 66%;
        background-color: #028ad3;
        color: #fff;
        font-size: 16px;
        gap: 9px;
    }

    .landing .landing-content .text h1 {
        font-size: 40px;
        width: 100%;
        text-align: right;
        color: #fff;
        font-weight: 800;
    }
    nav .nav-content.active {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        left: 0;
        position: inherit;
        transform: translateX(0);
        height: inherit;
        height: 100%;
        inset: 0;
        background-color: #fff;
        position: fixed;
        z-index: 15515;
    }

}



